<template>
  <BCard>
    <BRow class="justify-between items-center">
      <strong class="text-[20px] text-black font-semibold ml-1">
        Rekonsiliasi
      </strong></BRow>
    <BRow class="justify-end flex mr-1">
      <BButton
        class="button-ajukan"
        variants="primary"
        size="sm"
        @click="$router.push('/rekonsiliasi/pengajuan')"
      >
        <div class="flex items-center gap-3">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/add.svg"
          >
          Ajukkan File Pembanding
        </div>
      </BButton>
    </BRow>
    <BRow class="p-1 ">
      <BOverlay
        :show="loadingData"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        class=" w-100"
        rounded="sm"
      >
        <BTable
          bordered
          show-empty
          :items="dataFetch"
          :fields="fields"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-2"
        >
          <template #cell(created_at)="data">
            <span>
              {{ DAY_MONTH_YEAR(data.item.uploaded_date) }}
            </span>
          </template>
          <template #cell(expedition)="data">
            <span>
              <div class="flex justify-center">
                <img
                  style="height:30px;"
                  :src="data.item.shipping_image_url"
                >
              </div>
            </span>
          </template>
          <template #cell(description)="data">
            <span
              v-b-tooltip.hover
              :title="data.item.description"
              class="d-inline-block text-truncate"
              style="max-width: 150px !important;"
            >
              {{ data.item.description || '-' }}
            </span>
          </template>
          <template #cell(date_retur)="data">
            <span>
              {{ DAY_MONTH_YEAR(data.item.invoice_date) }}
            </span>
          </template>
          <template #cell(status)="data">
            <span
              :class="data.item.status"
              class="label_status"
            >
              {{ classStatus(data.item.status) }}
            </span>
          </template>
          <template #cell(aksi)="data">
            <span>
              <div class="flex justify-center">
                <BButton
                  v-if="
                    data.item.reconciliation_excel_url === null && data.item.status_download
                      === 'none' && data.item.status
                        === 'completed'
                  "
                  v-b-tooltip.hover
                  class="p-0"
                  variant="outline-light"
                  title="Click untuk generate link download file pembanding"
                  @click="generateDownload(data.item._id)"
                >
                  <img
                    :alt="data.item.reconciliation_excel_url"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/refresh-2.svg"
                  >
                </BButton>
                <BButton
                  v-if="
                    data.item.reconciliation_excel_url !== null && data.item.status === 'completed'
                  "
                  class="p-0"
                  variant="outline-light"
                  @click="downloadXcel(data.item.reconciliation_excel_url)"
                >
                  <img
                    :alt="data.item.reconciliation_excel_url"
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/document-download.svg"
                  >
                </BButton>
              </div>
            </span>
          </template>
        </BTable>
      </BOverlay>
    </BRow>
  </BCard>
</template>

<script>
import {
  BCard, BRow, BButton, BOverlay,
} from 'bootstrap-vue'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import { configTable } from './config'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BOverlay,
  },
  data() {
    return {
      fields: configTable,
      loadingData: false,
      dataFetch: [],
      DAY_MONTH_YEAR,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    classStatus(value) {
      if (value === 'completed') return 'Selesai'
      if (value === 'onprogress') return 'On Progress'
      if (value === 'failed') return 'Gagal'
      return ''
    },
    async getData() {
      this.loadingData = true
      const url = '/reconciliation/api/v1/reconciliations'
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.loadingData = false
          this.dataFetch = data
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
        })

      this.loadingData = false
    },
    async generateDownload(e) {
      const url = `/reconciliation/api/v1/reconciliations/${e}/download`
      await newAxiosIns
        .get(url)
        .then(res => {
          this.getData()
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
        })
    },
    downloadXcel(e) {
      window.open(e)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Rekonsiliasi.scss';
</style>
